import React from "react"
import { ArchiveIcon } from "@heroicons/react/outline"
import { SyncOutlined, UserOutlined, ReloadOutlined } from "@ant-design/icons"
import {
  IoGiftOutline,
  IoLocate,
  IoBasketball,
  IoFileTrayFull,
} from "react-icons/io5"
import {
  MdAttachMoney,
  MdControlPointDuplicate,
  MdPayment,
  MdSettings,
  MdOutlineUnsubscribe,
} from "react-icons/md"

import { ImBarcode } from "react-icons/im"
import { GiFeather } from "react-icons/gi"
import { FaShopify } from "react-icons/fa"
import { UserRole } from "../../modules/auth/types"
import { SettingsIcon, StoresIcon } from "../../ui/icons"

export interface MenuItem {
  label: string
  icon: React.ReactNode
  path?: string
  filter?: UserRole[]
  children?: MenuItem[]
}

export const menuItems: MenuItem[] = [
  {
    label: "Prodotti",
    icon: <ArchiveIcon width={18} />,
    path: "/products/",
    filter: ["admin", "manager", "support"],
  },
  {
    label: "Ordini",
    icon: <FaShopify width={18} />,
    path: "/orders/",
    filter: ["admin", "manager", "support"],
  },
  {
    label: "Metadati",
    icon: <IoLocate width={18} />,
    filter: ["admin", "manager"],
    children: [
      {
        label: "Categorie",
        icon: <MdControlPointDuplicate width={18} />,
        path: "/categories/",
        filter: ["admin", "manager"],
      },
      {
        label: "Tag",
        icon: <IoLocate width={18} />,
        path: "/tags/",
        filter: ["admin", "manager"],
      },
    ],
  },
  {
    label: "Parkod",
    icon: <ImBarcode width={18} />,
    path: "/parkod/",
    filter: ["admin", "manager"],
  },
  {
    label: "Checkout",
    icon: <IoBasketball width={18} />,
    filter: ["admin"],
    children: [
      {
        label: "Carrelli",
        icon: <IoBasketball width={18} />,
        path: "/basket/",
        filter: ["admin"],
      },
      {
        label: "Payments",
        icon: <MdPayment width={18} />,
        path: "/payments/",
        filter: ["admin"],
      },
    ],
  },
  {
    label: "CRM",
    icon: <UserOutlined />,
    filter: ["admin"],
    children: [
      {
        label: "Users",
        icon: <UserOutlined />,
        path: "/crm/users/",
        filter: ["admin", "manager"],
      },
      {
        label: "Dynamics",
        icon: <UserOutlined />,
        path: "/crm/dynamics/",
        filter: ["admin", "manager"],
      },
      {
        label: "Export",
        icon: <UserOutlined />,
        path: "/crm/export/",
        filter: ["admin"],
      },
      {
        label: "Disiscrizioni",
        icon: <MdOutlineUnsubscribe />,
        path: "/unsubscriptions/",
        filter: ["admin", "manager"],
      },
      {
        label: "Profilo Utente",
        icon: <UserOutlined />,
        path: "/crm/user-profile/",
        filter: ["admin", "manager"],
      },
      {
        label: "Disabled Profiles",
        icon: <UserOutlined />,
        path: "/crm/disabled-profiles/",
        filter: ["admin"],
      },
    ],
  },
  {
    label: "Admin",
    icon: <SettingsIcon />,
    filter: ["admin", "manager"],
    children: [
      {
        label: "Jobs",
        icon: <ReloadOutlined />,
        path: "/jobs/",
        filter: ["admin"],
      },
      {
        label: "Settings",
        icon: <GiFeather />,
        path: "/settings/",
        filter: ["admin"],
      },
      {
        label: "Feed",
        icon: <IoFileTrayFull width={18} />,
        path: "/feed/products/",
        filter: ["admin", "manager"],
      },
      {
        label: "Maintenance",
        icon: <MdSettings width={18} />,
        path: "/maintenance/",
        filter: ["admin"],
      },
      {
        label: "Backoffice Users",
        icon: <UserOutlined />,
        path: "/backoffice-users/",
        filter: ["admin"],
      },
      {
        label: "Publish",
        icon: <SyncOutlined width={18} />,
        path: "/publish/",
        filter: ["admin", "manager"],
      },
      {
        label: "Sync",
        icon: <SyncOutlined width={18} />,
        path: "/sync/",
        filter: ["admin"],
      },
    ],
  },
  {
    label: "Sconti",
    icon: <IoGiftOutline />,
    filter: ["admin", "manager"],
    children: [
      {
        label: "Promo",
        icon: <IoGiftOutline />,
        path: "/promotions/",
        filter: ["admin", "manager"],
      },
      {
        label: "Voucher",
        icon: <IoGiftOutline />,
        path: "/voucher/",
        filter: ["admin", "manager"],
      },
      {
        label: "Price Rules",
        icon: <MdAttachMoney />,
        path: "/price-rules/",
        filter: ["admin", "manager"],
      },
      {
        label: "Product Rules",
        icon: <MdAttachMoney />,
        path: "/product-rules/",
        filter: ["admin", "manager"],
      },
    ],
  },
  {
    label: "Calendari",
    icon: (
      <div className="mr-[1px]">
        <StoresIcon />
      </div>
    ),
    filter: ["admin", "manager"],
    path: "/calendars/",
    // children: [
    //   {
    //     label: "Calendari",
    //     icon: <IoCalendarOutline width={18} />,
    //     path: "/calendars/",
    //     filter: ["admin", "manager"],
    //   },
    // ],
  },
]
