import { Menu } from "antd"
import { useRouter } from "next/router"
import React from "react"
import { UserRole } from "../../modules/auth/types"
import { useAppSelector } from "../../state/store"
import { MenuItem, menuItems } from "./menu"

const showMenuItem = (item: MenuItem, userRoles?: string[]) => {
  if (
    !item.filter ||
    item.filter.length === 0 ||
    !userRoles ||
    userRoles.length === 0
  ) {
    return
  }

  return userRoles.some((x) => item.filter?.includes(x as UserRole))
}

const Sidebar = () => {
  const router = useRouter()
  const { account } = useAppSelector((state) => state.user)

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={[
        menuItems.findIndex((x) => x.path === router.pathname)?.toString(),
      ]}
      className="mt-6"
      items={menuItems
        .filter((x) => showMenuItem(x, account?.roles))
        .map((x, i) => ({
          icon: x.icon,
          label: x.label,
          key: i.toString(),
          onClick: () => (x.path ? router.push(x.path) : {}),
          children: x.children?.map((x, j) => ({
            icon: x.icon,
            label: x.label,
            key: [i, j].join("."),
            onClick: () => (x.path ? router.push(x.path) : {}),
          })),
        }))}
    ></Menu>
  )
}

export default Sidebar
