import React from "react"
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  DownloadOutlined,
  PlusOutlined,
  MinusOutlined,
  SettingOutlined,
  LinkOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons"
import {
  IoWarningOutline,
  IoInformationCircleOutline,
  IoCheckmarkCircleOutline,
} from "react-icons/io5"
import { MdErrorOutline } from "react-icons/md"
import { IconBaseProps } from "react-icons/lib"
import { FaStore } from "react-icons/fa"

export const WarningIcon = (props: IconBaseProps) => (
  <IoWarningOutline {...props} />
)
export const InformationIcon = (props: IconBaseProps) => (
  <IoInformationCircleOutline {...props} />
)
export const CheckmarkIcon = (props: IconBaseProps) => (
  <IoCheckmarkCircleOutline {...props} />
)
export const ErrorIcon = (props: IconBaseProps) => <MdErrorOutline {...props} />

export const MinusIcon = () => <MinusOutlined />

export const AddIcon = () => <PlusOutlined />

export const ArrowUpIcon = () => <ArrowUpOutlined />
export const ArrowDownIcon = () => <ArrowDownOutlined />

export const DownloadIcon = () => <DownloadOutlined />
export const SettingsIcon = () => <SettingOutlined />

export const StoresIcon = () => <FaStore />

export const LinkIcon = () => <LinkOutlined />

export const DeleteIcon = () => <DeleteOutlined />
export const CloseIcon = () => <CloseOutlined />
