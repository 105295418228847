import React from "react"
import { Layout } from "antd"
import Sidebar from "./Sidebar"

export interface Props {
  children: any
}

const DefaultLayout = ({ children }: Props) => {
  const { Content, Sider } = Layout

  return (
    <>
      <Layout className="min-h-screen">
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type)
          }}
        >
          <div className="logo">
            {/* <img src="https://cdn.sanity.io/images/srnt2ltl/production/f47000ce402d5806f4a7837aaee225a89eaa1c3c-1130x328.svg?w=250" /> */}
          </div>
          <Sidebar />
        </Sider>
        <Layout>
          {/* <Header style={{ background: "#fff", padding: 0 }} /> */}
          <Content style={{ margin: "24px 16px 0" }}>
            <main style={{ padding: 24, background: "#fff", minHeight: 360 }}>
              {children}
            </main>
          </Content>
          {/* <Footer style={{ textAlign: "center" }}>Modus</Footer> */}
        </Layout>
      </Layout>
    </>
  )
}

export default DefaultLayout
