import { useRouter } from "next/router"
import React from "react"
import { DefaultLayout } from "../layouts"

export default function Home() {
  const router = useRouter()
  React.useEffect(() => {
    router.push("/products")
  }, [])

  return (
    <DefaultLayout>
      <h1>Modus Product Tool</h1>
    </DefaultLayout>
  )
}
